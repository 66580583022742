// import { YMapMarkerProps } from "@yandex/ymaps3-types/imperative/YMapMarker";
import { IPoint } from "@/views/repayment/intefaces";

export function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("PunktDatabase", 1);

    request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains("punkts")) {
        db.createObjectStore("punkts", { keyPath: "id" });
      }
    };

    request.onsuccess = (event: Event) => {
      resolve((event.target as IDBOpenDBRequest).result);
    };

    request.onerror = (event: Event) => {
      reject((event.target as IDBOpenDBRequest).error);
    };
  });
}
function cloneObject<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}
export function savePunkts(
  db: IDBDatabase,
  punkts: Array<IPoint>
): Promise<void> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["punkts"], "readwrite");
    const store = transaction.objectStore("punkts");
    store.clear(); // Clear existing data
    punkts.forEach((punkt: IPoint) => {
      const clonedPunkt = cloneObject(punkt);
      store.put(clonedPunkt);
    });

    transaction.oncomplete = () => {
      resolve();
    };

    transaction.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}

export function getPunkts(db: IDBDatabase): Promise<Array<IPoint>> {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["punkts"], "readonly");
    const store = transaction.objectStore("punkts");
    const request = store.getAll();

    request.onsuccess = (event: Event) => {
      resolve((event.target as IDBRequest).result as Array<IPoint>);
    };

    request.onerror = (event: Event) => {
      reject((event.target as IDBRequest).error);
    };
  });
}
