<template>
  <input
    type="text"
    class="form-control"
    :id="id"
    :placeholder="placeholder"
    :value="modelValue"
    @input="updateInput"
  />
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

// Определение пропсов с использованием TypeScript для типизации
defineProps<{
  modelValue?: string | number;
  placeholder?: string;
  id: string;
}>();

// Определение функции emit для эмитации событий
const emit = defineEmits<{
  (event: "update:modelValue", value: string | number): void;
}>();

// Функция обновления входного значения и эмитирования изменения
function updateInput(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  emit("update:modelValue", inputElement.value);
}
</script>

<style scoped>
/* Здесь можно добавить стили, если это необходимо */
</style>
