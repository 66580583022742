import axios from "@/axiosInstance";
import { ref } from "vue";
import { ILikeOrder } from "../interfaces"; // Убедитесь, что путь до интерфейсов правильный
import store from "@/store";

class LikeService {
  likes = ref<Array<ILikeOrder>>([]);
  isLoading = ref(true);

  async fetching() {
    this.isLoading.value = true;
    try {
      const response = await axios.get(`warming/get/likes`);
      this.likes.value = response.data;
    } catch (e) {
      alert("Error");
    } finally {
      this.isLoading.value = false;
    }
  }

  async createLikes(likeForm: any) {
    try {
      const response = await axios.post("warming/post/create", likeForm.value);
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getLikes() {
    // onMounted(this.fetching);
    this.fetching(); // При первом вызове getLikes он автоматически загружает данные
    return { isLoading: this.isLoading, likes: this.likes };
  }
}

export default new LikeService();
