<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-sm p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content">
      <div
        class="modal-header d-flex justify-content-between align-items-center p-3"
      >
        <h5 class="modal-title mb-0">Мои отзывы</h5>
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body p-0">
        <div class="overflow-auto" style="max-height: 400px">
          <div class="p-3">
            <ReviewCard
              v-for="review in reviews"
              :key="review.id"
              :review="review"
              @update="VoteCardUpdate"
            />
          </div>
        </div>
        <div
          class="modal-footer d-flex justify-content-between align-items-center p-3"
        >
          <div class="col-9">
            <span>Мои отзывы: {{ reviews.length }}</span>
            <br />
            <i class="bx bx-like">
              {{ reviews.reduce((sum, item) => sum + item.pluses, 0) }}
            </i>
            <i class="bx bx-dislike">
              {{ reviews.reduce((sum, item) => sum + item.minuses, 0) }}
            </i>
          </div>
          <div class="col-3">
            <MyButton @click="emit('closeicon')">Закрыть</MyButton>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { Ref, defineEmits, defineProps, ref } from "vue";
import CloseButton from "@/components/UI/CloseButton.vue";
import MyButton from "@/components/UI/MyButton.vue";
import { VueFinalModal } from "vue-final-modal";
import { Feedback } from "@/hooks/interfaces";
import ReviewCard from "../components/ReviewCard.vue";
import { ReviewVote } from "../interfaces";

const props = defineProps<{
  feedbacks: Ref<Array<Feedback>>;
}>();
const reviews = ref(props.feedbacks);
const emit = defineEmits<{
  (e: "closeicon"): void;
  (event: "update", vote: ReviewVote): void;
}>();
const VoteCardUpdate = (vote: ReviewVote) => {
  emit("update", vote);
};
</script>
