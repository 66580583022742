<template #body="props">
  <div class="vue-notification-my">
    <div class="row">
      <div class="col-3">
        <p class="title">
          {{ props.notification.title }}
        </p>
      </div>
      <div class="col-9 d-flex justify-content-end">
        <button class="btn-close" @click="close"></button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div v-html="props.notification.text"></div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>
<style>
.vue-notification-my {
  /* // styling */
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: #1a0606;

  /* // default (blue) */
  background-color: white;
  border: 1px solid #695cfe;
  border-left: 5px solid #695cfe;
  border-radius: 10px;
  font-size: medium;

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background-color: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}
</style>
