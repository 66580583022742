<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
              Профиль
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
        </div>
        <h4>Изменение данных профиля</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="username" class="form-label">Имя пользователя</label>
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="Имя пользователя"
                aria-label="username"
                autocomplete="off"
                v-model="userProfile.name"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="email" class="form-label">E-mail</label>
              <input
                type="text"
                class="form-control"
                id="email"
                disabled
                placeholder="e-mail"
                aria-label="email"
                v-model="userProfile.email"
              />
              <a
                v-if="userProfile.is_verify"
                href="#"
                @click="confirmAccount"
                class="d-block mt-2"
                >Подтвердить аккаунт</a
              >
              <p v-else><i class="bx bx-check"></i>Аккаунт подтвержден</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="phone" class="form-label">Номер телефона</label>
              <input
                type="text"
                class="form-control"
                id="phone"
                placeholder="Номер телефона"
                aria-label="phone"
                v-model="userProfile.phone"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <MyButton @click="SaveProfile">Сохранить</MyButton>
          </div>
        </div>
        <hr />
        <h4>Изменение пароля</h4>
        <ChangePasswordForm @submit="changePassword" />
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import ChangePasswordForm from "./components/ChangePassswordForm.vue";
import AuthService from "./hooks/AuthService";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";

const store = useStore();
const crumbs = [
  {
    text: "",
    url: "/profile",
  },
  {
    text: "Профиль",
  },
];
const userProfile = ref({
  name: store.state.user.username,
  email: store.state.user.email,
  phone: store.state.user.phone,
  is_verify: false,
});
onMounted(async () => {
  const profile = await AuthService.getProfile();
  if (profile.value) {
    userProfile.value.name = profile.value.name;
    userProfile.value.phone = profile.value.phone;
    userProfile.value.is_verify = profile.value.is_verify;
  }
});
// const newPassword = ref({
//   password: "",
//   password2: "",
//   errors: {
//     password: "",
//     password2: ""
//   }
// });
const changePassword = async (newPassword: string) => {
  let res = await AuthService.changePassword(newPassword);
  if (res == true) {
    notify({
      text: "Пароль успешно изменен.",
    });
  }
};
const SaveProfile = async () => {
  let res = await AuthService.changeProfile(userProfile.value);
  if (res == true) {
    notify({
      text: "Данные профиля успешно обновлены.",
    });
  }
};
const confirmAccount = async () => {
  let res = await AuthService.sendVerifyEmail();
  if (res == true) {
    notify({
      text: "Отпрвлено письмо с подтверждением на почту",
    });
  }
};
</script>
