import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
// import "bootstrap/dist/css/bootstrap.min.css";
import "vue-final-modal/style.css";
import { createYmaps } from "vue-yandex-maps";
import { createVfm } from "vue-final-modal";
import Notifications from "@kyvg/vue3-notification";
import MyNotification from "./components/UI/MyNotification.vue";
import axiosInstance from "./axiosInstance";

const vfm = createVfm();
const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;
app
  .use(
    createYmaps({
      apikey: process.env.VUE_APP_YANDEX_MAP_API_TOKEN,
      initializeOn: "onPluginInit",
    })
  )
  .use(vfm)
  .use(Notifications, {
    component: MyNotification,
  })
  .use(store)
  .use(router)
  .mount("#app");
