<template>
  <form method="post" id="signupForm" @submit.prevent>
    <div class="mb-3">
      <label for="signupemail" class="fw-bold"
        >Электронная почта <span class="text-danger">*</span></label
      >
      <input
        type="email"
        name="signupemail"
        id="signupemail"
        class="form-control"
        placeholder="Введите действительный адрес электронной почты"
        required
        v-model="userRegistration.email"
      />
      <div class="text-danger">
        <em>Это будет ваше имя для входа!</em>
      </div>
    </div>
    <div class="mb-3">
      <label for="signupusername" class="fw-bold"
        >Имя пользователя <span class="text-danger">*</span></label
      >
      <input
        type="text"
        name="signupusername"
        id="signupusername"
        class="form-control"
        placeholder="Выберите имя пользователя"
        required
        v-model="userRegistration.name"
      />
    </div>
    <div class="mb-3">
      <label for="signupphone" class="fw-bold">Телефон</label>
      <input
        type="text"
        name="signupphone"
        id="signupphone"
        class="form-control"
        placeholder="(000)-(0000000)"
        v-model="userRegistration.phone"
      />
    </div>
    <div class="mb-3">
      <label for="signuppassword" class="fw-bold"
        >Пароль <span class="text-danger">*</span></label
      >
      <input
        type="password"
        name="signuppassword"
        id="signuppassword"
        class="form-control"
        placeholder="***********"
        pattern="^\S{6,}$"
        required
        v-model="userRegistration.password"
        @input="validatePassword"
      />
      <div
        v-if="userRegistration.password.length > 0 && !valid"
        style="color: red"
      >
        Пароль должен содержать как минимум одну заглавную букву, одну цифру и
        быть не менее 8 символов
      </div>
    </div>
    <div class="mb-3">
      <label for="signupcpassword1" class="fw-bold"
        >Подтверждение пароля <span class="text-danger">*</span></label
      >
      <input
        type="password"
        name="signupcpassword"
        id="signupcpassword1"
        class="form-control"
        pattern="^\S{6,}$"
        placeholder="***********"
        required
        v-model="userRegistration.password2"
      />
      <div
        v-if="userRegistration.password != userRegistration.password2"
        style="color: red"
      >
        Пароли должны совпадать
      </div>
    </div>
    <div class="mb-3">
      <div class="form-check">
        <input
          type="checkbox"
          name="signupcondition"
          id="signupcondition"
          class="form-check-input"
          required
        />
        <label for="signupcondition" class="form-check-label">
          Я соглашаюсь с
          <a href="javascript:void(0);">Условиями и Положениями</a> регистрации.
        </label>
      </div>
    </div>
    <div class="mb-3">
      <MyButton
        type="submit"
        name="signupsubmit"
        class="btn btn-primary w-100"
        @click="Submit"
      >
        Зарегистрироваться
      </MyButton>
    </div>
  </form>
</template>
<script setup lang="ts">
import { reactive, defineProps, defineEmits } from "vue";
import { IUserRegistration } from "@/views/auth/interfaces";
import MyButton from "@/components/UI/MyButton.vue";

const props = defineProps({
  userRegistration: {
    type: Object as () => IUserRegistration,
    required: true,
  },
});
const userRegistration = reactive(props.userRegistration);
const emits = defineEmits(["submit:userRegistration"]);
let valid: boolean;
const validatePassword = () => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
  valid = passwordRegex.test(userRegistration.password);
};
const Submit = () => {
  if (valid && userRegistration.password == userRegistration.password2) {
    emits("submit:userRegistration");
  }
};
</script>
