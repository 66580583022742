<template>
  <div
    class="tooltip"
    :class="{ visible: show }"
    style="position: absolute; z-index: 100"
  >
    <div class="tooltip-inner p-2 bg-dark text-white rounded">
      <button
        @click="closeToolTip()"
        class="close-button text-white"
        style="
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
        "
      >
        &times;
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch, toRefs } from "vue";
import CloseButton from "./UI/CloseButton.vue";

const props = defineProps({
  product: {
    required: true,
  },
  show: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["closeTip"]);

const closeToolTip = () => {
  emit("closeTip");
};
// Использование toRefs для сохранения реактивности вложенных свойств props
const { show } = toRefs(props);
// Локальное реактивное состояние для управления видимостью подсказки
const isVisible = ref(false);

// Смотрим на изменение свойства show, чтобы изменять видимость подсказки
watch(show, (newVal) => {
  isVisible.value = newVal;
});
</script>

<style scoped>
/* Применяем scoped стили для стилизации подсказки */
.tooltip.visible {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.tooltip-inner {
  /* Стили внутреннего блока подсказки */
}
</style>
