<template>
  <form method="post" id="singninFrom" @submit.prevent>
    <div class="mb-3">
      <label for="email" class="fw-bold"
        >Email <span class="text-danger">*</span></label
      >
      <input
        type="email"
        name="email"
        id="email"
        class="form-control"
        placeholder="Введите корректный адрес электронной почты"
        required
        v-model="userLogin.username"
      />
    </div>
    <div class="mb-3">
      <label for="password" class="fw-bold"
        >Пароль <span class="text-danger">*</span></label
      >
      <input
        type="password"
        name="password"
        id="password"
        class="form-control"
        placeholder="***********"
        required
        v-model="userLogin.password"
      />
    </div>
    <div class="mb-3">
      <div class="row">
        <div class="col">
          <div class="form-check">
            <input
              type="checkbox"
              name="condition"
              id="condition"
              class="form-check-input"
            />
            <label for="condition" class="form-check-label"
              >Запомнить меня</label
            >
          </div>
        </div>
        <div class="col text-end">
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#forgotPass"
            @click="emitClickModal"
            >Забыли пароль?
          </a>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <MyButton
        type="submit"
        name="submit"
        class="btn btn-primary w-100"
        @click="Submit"
        >Вход</MyButton
      >
    </div>
    <div class="mb-3">
      <div id="telegram-login-widget"></div>
    </div>
  </form>
</template>
<script setup lang="ts">
import { reactive, defineProps, defineEmits, onMounted } from "vue";
import { IUserLogin } from "@/views/auth/interfaces";
import MyButton from "@/components/UI/MyButton.vue";
// import TelegramLogin from "./TelegramLogin.vue";

const props = defineProps({
  userLogin: {
    type: Object as () => IUserLogin,
    required: true,
  },
});
const userLogin = reactive(props.userLogin);
const emits = defineEmits(["submit:userLogin", "click:openModal"]);
const Submit = () => {
  emits("submit:userLogin");
};
const emitClickModal = () => {
  emits("click:openModal");
};
onMounted(() => {
  const script = document.createElement("script");
  script.async = false;
  script.src = "https://telegram.org/js/telegram-widget.js?22";
  script.setAttribute("data-telegram-login", "reito_bot");
  script.setAttribute("data-size", "large");
  script.setAttribute("data-auth-url", "https://mymp.pro/api/token/signup");
  script.setAttribute("data-request-access", "write");
  document.getElementById("telegram-login-widget")?.appendChild(script);
});
</script>
