<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body" v-if="!currentProduct">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-2 text-left font-bold font-up deep-purple-text">
              Лайки на отзывы
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
        </div>
        <!-- Grid column -->
        <div class="col-md-12">
          <form class="row g-3 align-items-center" @submit.prevent>
            <div class="col-12 col-sm-4">
              <label for="aticle" class="visually-hidden"
                >Введите артикул</label
              >
              <TextInput
                v-model="article"
                :placeholder="'Введите артикул'"
                :id="'article'"
              ></TextInput>
            </div>
            <div class="col-6 col-sm-auto mt-4">
              <MyButton @click="AppendProduct">Добавить</MyButton>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <!--Table head-->
              <thead>
                <tr>
                  <th>#</th>
                  <th>Картинка</th>
                  <th>Ссылка на товар</th>
                  <th>Лайки</th>
                  <th>Дизлайки</th>
                  <th>Прогресс</th>
                  <th>Стоимость</th>
                  <th>Статус</th>
                  <th>Дата создания</th>
                  <th>Дата окончания</th>
                  <th></th>
                </tr>
              </thead>
              <!--Table head-->
              <!--Table body-->
              <tbody v-if="!ReviewLikeService.isLoading.value">
                <tr
                  v-for="(cart, index) in ReviewLikeService.orders.value"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <div class="col-auto">
                      <ImageContainer
                        :photo_url="cart.product.photo_url"
                      ></ImageContainer>
                    </div>
                    <!-- <img
                    :src="cart.product.photo_url"
                    :alt="cart.product.photo_url"
                    width="50"
                    height="50"
                  /> -->
                  </td>
                  <td>{{ cart.product.article }}</td>
                  <td>{{ cart.likes }}</td>
                  <td>{{ cart.dislikes }}</td>
                  <td>{{ cart.done }}/{{ cart.total }}</td>
                  <td v-if="service">{{ service.cost * cart.total }} руб.</td>
                  <td>
                    <template v-if="cart.done === cart.total">
                      <StatusSpan
                        :color-class="statusClassStatus('DONE').class"
                        >{{ statusClassStatus("DONE").text }}</StatusSpan
                      >
                    </template>
                    <template v-else
                      ><StatusSpan
                        :color-class="statusClassStatus('PROCCESS').class"
                        >{{ statusClassStatus("PROCCESS").text }}</StatusSpan
                      ></template
                    >
                  </td>
                  <td>{{ cart.date_start }}</td>
                  <td>{{ cart.date_end }}</td>
                </tr>
              </tbody>
              <template v-else>
                <RowSkeleton
                  v-for="index in 5"
                  :key="index"
                  :columns="9"
                ></RowSkeleton>
              </template>
              <!--Table body-->
            </table>
          </div>
        </div>
      </div>
      <template v-if="currentProduct">
        <div class="row">
          <div class="col-3 col-sm-2 col-md-1">
            <ImageContainer
              :photo_url="currentProduct.photo_url"
            ></ImageContainer>
          </div>
          <div class="col">
            <h1 class="mb-4">Отзывы к "{{ currentProduct.name }}"</h1>
          </div>
          <div class="col-12 col-sm-auto ms-auto mt-3">
            <MyButton @click="Reset()">Закрыть</MyButton>
          </div>
        </div>
        <div class="container mt-3">
          <div
            class="d-flex flex-column flex-md-row justify-content-between mb-3"
          >
            <!-- Левая часть: Кнопки фильтрации -->
            <div class="mb-3 mb-md-0">
              <button class="btn btn-primary me-2 mb-2 mb-md-0">
                Сначала с фото
              </button>
              <button class="btn btn-outline-secondary">
                Цена: {{ service?.cost }}Р / шт.
              </button>
            </div>

            <!-- Правая часть: Поиск и сортировка -->
            <div>
              <div class="d-flex flex-column flex-sm-row">
                <input
                  type="text"
                  class="form-control mb-2 mb-sm-0"
                  placeholder="Найти"
                  v-model="searchQuery"
                />
                <select
                  class="form-select ms-sm-2 mb-2 mb-sm-0"
                  v-model="sortOption"
                >
                  <option value="date">Сортировать: по дате</option>
                  <option value="descending">Сортировать: по полезности</option>
                </select>
                <select class="form-select ms-sm-2" v-model="reviewsPerPage">
                  <option value="20">Показывать по: 20</option>
                  <option value="40">Показывать по: 40</option>
                  <option value="80">Показывать по: 80</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Навигация по страницам -->
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="prevPage"
                  ><i class="bx bxs-chevron-left"></i
                ></a>
              </li>
              <li
                class="page-item"
                v-for="page in pagesInRange"
                :key="page"
                :class="{ active: page === currentPage }"
              >
                <a
                  class="page-link"
                  href="#"
                  @click.prevent="() => (currentPage = page)"
                  >{{ page }}</a
                >
              </li>
              <li
                class="page-item"
                :class="{ disabled: currentPage === totalPages }"
              >
                <a class="page-link" href="#" @click.prevent="nextPage"
                  ><i class="bx bxs-chevron-right"></i
                ></a>
              </li>
            </ul>
          </nav>
        </div>

        <div class="row mb-5">
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4"
            v-for="review in paginatedReviews"
            :key="review.id"
          >
            <ReviewCard :review="review" @update="VoteCardUpdate" />
          </div>
        </div>
        <!-- Фиксированная панель, активированная при наличии лайков -->
        <div
          v-if="reviewCard.length > 0"
          class="fixed-panel fixed-bottom bg-light shadow"
          :class="['main-content', { 'is-expanded': !isCollapsed }]"
        >
          <div class="container">
            <div
              class="d-flex flex-md-row justify-content-between align-items-center py-2"
            >
              <div class="mb-2 mb-md-0">
                <span>Мои отзывы: {{ reviewCard.length }}</span>
                <br />
                <!-- Пример числа отзывов -->
                <i class="bx bx-like me-1">
                  {{ reviewCard.reduce((sum, item) => sum + item.pluses, 0) }}
                </i>
                <i class="bx bx-dislike">
                  {{ reviewCard.reduce((sum, item) => sum + item.minuses, 0) }}
                </i>
              </div>
              <div class="d-flex flex-row">
                <button @click="openReviewCard" class="btn btn-primary me-2">
                  Мои отзывы
                </button>
                <button @click="PayReviewLikes" class="btn btn-primary">
                  Оплатить
                </button>
              </div>
            </div>
          </div>
        </div>

        <ModalsContainer :feedbacks="reviewCard" backdrop="true" />
      </template>
    </div>
  </LayuotPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import ReviewCard from "./components/ReviewCard.vue";
import LayuotPage from "../LayuotPage.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import WBService from "@/hooks/WBService";
import { Feedback, IService } from "@/hooks/interfaces";
import { IRepaymentProduct } from "../repayment/intefaces";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import { ReviewVote } from "./interfaces";
import { useStore } from "vuex";
import { ModalsContainer, useModal } from "vue-final-modal";
import ReviewCartModal from "./modals/ReviewCartModal.vue";
import ReviewLikeService from "./hooks/ReviewLikeService";
import { getService } from "@/hooks";
import { notify } from "@kyvg/vue3-notification";
import { parse } from "date-fns";
import { statusClassStatus } from "@/hooks";
import StatusSpan from "@/components/UI/StatusSpan.vue";

const crumbs = [
  {
    text: "",
    url: "/reviewlike",
  },
  {
    text: "Лайки на отзывы",
  },
];
const service = ref<IService>();
const store = useStore();
const isCollapsed = computed(() => store.state.isCollapse);
const article = ref("");
const currentProduct = ref<IRepaymentProduct>();
const errors = ref({
  article: true,
});
const reviews = ref<Feedback[]>([]);

const currentPage = ref(1);

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};
const searchQuery = ref("");
const sortOption = ref("date"); // Может быть 'date' или 'descending'

const filteredAndSortedReviews = computed(() => {
  let filtered = reviews.value.filter((review) =>
    review.text.toLowerCase().includes(searchQuery.value.toLowerCase())
  );

  if (sortOption.value === "date") {
    filtered = filtered.sort((a, b) => {
      return parseDate(b.createdDate) - parseDate(a.createdDate);
    });
  } else if (sortOption.value === "descending") {
    filtered = filtered.sort((a, b) => b.productValuation - a.productValuation);
  }

  return filtered;
});
function parseDate(dateString: string) {
  // Assuming dateString is in format "dd.MM.yyyy HH:mm:ss"
  return parse(dateString, "dd.MM.yyyy HH:mm:ss", new Date());
}
const reviewsPerPage = ref(20);
const paginatedReviews = computed(() => {
  const start = (currentPage.value - 1) * reviewsPerPage.value;
  const end = start + reviewsPerPage.value;
  return filteredAndSortedReviews.value.slice(start, end);
});

const totalPages = computed(() =>
  Math.ceil(filteredAndSortedReviews.value.length / reviewsPerPage.value)
);
const pagesInRange = computed(() => {
  const range = 3;
  const start = Math.max(1, currentPage.value - range);
  const end = Math.min(totalPages.value, currentPage.value + range);

  let pages: number[] = [];
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});
const AppendProduct = async () => {
  const regex = /^[\d]{5,9}$/;
  errors.value.article = regex.test(article.value);
  if (errors.value.article) {
    const responseProduct = await WBService.getProduct(article.value);
    if (responseProduct) {
      currentProduct.value = responseProduct;
      const { isLoading, feedbacks } = await WBService.getReviewProduct(
        article.value
      );
      reviews.value = feedbacks.value.feedbacks;
    }
  } else {
    errors.value.article = false;
  }
};
const reviewCard = ref<Array<Feedback>>([]);
// const totalLikes = computed((review: Feedback)=>{})
const VoteCardUpdate = (vote: ReviewVote) => {
  const reviewIndex = reviewCard.value.findIndex(
    (review) => review.id === vote.id
  );

  if (reviewIndex !== -1) {
    // Если отзыв с таким ID найден, увеличиваем соответствующий счетчик
    if (vote.vote === "minus") {
      reviewCard.value[reviewIndex].minuses = vote.count;
    } else {
      reviewCard.value[reviewIndex].pluses = vote.count;
    }
    if (
      reviewCard.value[reviewIndex].pluses == 0 &&
      reviewCard.value[reviewIndex].minuses == 0
    ) {
      reviewCard.value.splice(reviewIndex, 1);
    }
  } else {
    const newReview = reviews.value.find((review) => review.id === vote.id);
    if (newReview) {
      newReview.pluses = vote.vote === "plus" ? 1 : 0;
      newReview.minuses = vote.vote === "minus" ? 1 : 0;
    }
    reviewCard.value.push(newReview as Feedback);
  }
};
const Reset = async () => {
  reviews.value = [];
  currentProduct.value = ref<IRepaymentProduct>().value;
  reviewCard.value.splice(-1);
  await ReviewLikeService.getReviewLikes();
  article.value = "";
};
const PayReviewLikes = async () => {
  let res = await ReviewLikeService.createReviewLikes(
    article.value,
    reviewCard.value
  );
  if (res) {
    notify({
      text: "Лайки на отзывы добавлены",
    });
    await Reset();
  }
};
const { open: openReviewCard, close: closeReviewCard } = useModal({
  component: ReviewCartModal,
  attrs: {
    feedbacks: reviewCard,
    onCloseicon() {
      closeReviewCard();
    },
    onUpdate(vote: ReviewVote) {
      VoteCardUpdate(vote);
    },
  },
});
onMounted(async () => {
  await ReviewLikeService.getReviewLikes();
  service.value = (await getService("reviewlike")).value;
});
</script>

<style scoped>
.fixed-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  /* margin-left: 16em; */
  right: 0; /* Обеспечивает ширину во всю ширину родительского блока */
  height: 80px; /* Увеличенная высота */
  z-index: 1030; /* Высокий z-index для уверенности, что панель будет поверх всех элементов */
}
.main-content {
  transition: margin-left 0.3s ease;
  margin-left: 90px;
  max-width: 100%;
}
@media (max-width: 767.98px) {
  /* Для экранов меньше размера md */
  .main-content {
    margin-left: 85px !important; /* Убираем смещение основного контента */
  }
}
.is-expanded {
  margin-left: 255px; /* Увеличьте значение в зависимости от вашей нужды */
  max-width: 100%;
}
</style>
