<template>
  <div class="input-group number-input">
    <button
      class="btn-sm btn btn-outline-secondary"
      type="button"
      @click="decrement"
    >
      -
    </button>
    <input
      type="number"
      class="form-control text-center"
      :step="step"
      min="0"
      :max="max ? max : 100"
      :value="localComputed"
      @input="updateInput"
      readonly
    />
    <button
      class="btn-sm btn btn-outline-secondary"
      type="button"
      @click="increment"
    >
      +
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from "vue";

const props = defineProps<{
  modelValue: number;
  step: number;
  max: number | undefined;
}>();

const emit = defineEmits(["update:modelValue"]);

const localComputed = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: number) {
    emit("update:modelValue", newValue);
  },
});

function increment() {
  const max_count = props.max ? props.max : 100;
  if (localComputed.value < max_count) {
    localComputed.value += props.step;
  }
}

function decrement() {
  if (localComputed.value > props.step) {
    localComputed.value -= props.step;
  }
}

function updateInput(event: Event) {
  emit("update:modelValue", parseInt((event.target as HTMLInputElement).value));
}
</script>
<style scoped>
.number-input,
input {
  font-size: small !important;
}
</style>
