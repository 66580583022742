import axios from "@/axiosInstance";
import { ref } from "vue";
import { IReviewLikeOrder } from "../interfaces"; // Убедитесь, что путь до интерфейсов правильный
import store from "@/store";
import { Feedback } from "@/hooks/interfaces";

class ReviewLikeService {
  orders = ref<Array<IReviewLikeOrder>>([]);
  isLoading = ref(true);

  async fetching() {
    this.isLoading.value = true;
    try {
      const response = await axios.get(`warming/get/votes`);
      this.orders.value = response.data;
    } catch (e) {
      alert("Error");
    } finally {
      this.isLoading.value = false;
    }
  }

  async createReviewLikes(article: string, reviews: Array<Feedback>) {
    const data = {
      article: article,
      reviews: reviews,
    };
    try {
      const response = await axios.post("warming/post/votes/create", data);
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getReviewLikes() {
    // onMounted(this.fetching);
    this.fetching(); // При первом вызове getLikes он автоматически загружает данные
    return { isLoading: this.isLoading, orders: this.orders };
  }
}

export default new ReviewLikeService();
