<template>
  <!-- Модальное окно -->
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-sm p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content" v-if="review">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h5 class="modal-title">Оставить отзыв</h5>
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body p-0 overflow-auto" style="max-height: 400px">
        <!-- Форма отзыва -->
        <form @submit.prevent="submitReview" class="p-2">
          <!-- Выбор пола покупателя -->
          <div class="mb-2">
            <label for="genderSelect" class="form-label">Пол покупателя</label>
            <select
              id="genderSelect"
              class="form-select"
              v-model="reviewForm.gender"
              @change="updateCurrentCartPvz"
            >
              <option
                value="male"
                :disabled="
                  !review.gender_info.male ||
                  review.gender_info.male.length === 0
                "
              >
                Мужской
                {{
                  review.gender_info.male ? review.gender_info.male.length : 0
                }}
              </option>
              <option
                value="female"
                :disabled="
                  !review.gender_info.female ||
                  review.gender_info.female.length === 0
                "
              >
                Женский
                {{
                  review.gender_info.female
                    ? review.gender_info.female.length
                    : 0
                }}
              </option>
            </select>
          </div>
          <!-- Пункт выдачи -->
          <div class="mb-2">
            <label for="pickupPoint" class="form-label">Пункт выдачи</label>
            <select
              id="pickupPoint"
              class="form-select"
              v-model="reviewForm.cart"
              :disabled="currentCartPvz.length === 0"
            >
              <option
                v-for="(punkt, index) in currentCartPvz"
                :key="index"
                :value="punkt.id"
              >
                {{ "Выкуп: " + punkt.id.toString() + " " + punkt.address }}
              </option>
            </select>
          </div>
          <!-- Запланировать отзыв -->
          <div class="mb-2">
            <label for="scheduleReview" class="form-label"
              >Запланировать отзыв</label
            >
            <input
              type="datetime-local"
              class="form-control"
              id="scheduleReview"
              v-model="reviewForm.publish_date"
              :min="minDate"
              :max="maxDate"
            />
            <div v-if="errors.publishDate" style="color: red">
              {{ errors.publishDate }}
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label">Рейтинг товара</label>
            <StarRating v-model="reviewForm.rating" />
            <div v-if="errors.rating" style="color: red">
              {{ errors.rating }}
            </div>
          </div>
          <!-- Отзыв о товаре -->
          <div class="mb-2">
            <label for="productReview" class="form-label">Отзыв о товаре</label>
            <textarea
              class="form-control"
              id="productReview"
              rows="4"
              placeholder="Например, хорошие наушники"
              v-model="reviewForm.review"
            ></textarea>
          </div>
          <div class="mb-2">
            <label for="photoUpload" class="form-label"
              >Фотографии товара</label
            >
            <input
              type="file"
              class="form-control"
              id="photoUpload"
              multiple
              @change="handleFileUpload"
            />
          </div>
          <!-- Кнопки управления формой -->
          <div class="d-flex justify-content-between">
            <MyButton type="reset" class="btn btn-secondary">Сбросить</MyButton>
            <MyButton type="submit" class="btn btn-primary">Отправить</MyButton>
          </div>
        </form>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, Ref, watch } from "vue";
import MyButton from "@/components/UI/MyButton.vue";
import StarRating from "../components/StarRating.vue";
import { VueFinalModal } from "vue-final-modal";
import { IProductReview } from "../intefaces";
import CloseButton from "@/components/UI/CloseButton.vue";
import { getFormattedDate } from "@/hooks";

const props = defineProps<{
  review: Ref<IProductReview>;
}>();
const emit = defineEmits<{
  (e: "closeicon"): void;
  (event: "update", newReview: FormData): void;
}>();
// Реактивное свойство для управления видимостью модального окна
const showModal = ref(false);
const review = ref(props.review);
const reviewForm = ref({
  article: review.value.product.article,
  cart:
    review.value.gender_info.male && review.value.gender_info.male.length > 0
      ? review.value.gender_info.male[0].id
      : review.value.gender_info.female &&
        review.value.gender_info.female.length > 0
      ? review.value.gender_info.female[0].id
      : null,
  gender:
    review.value.gender_info.male && review.value.gender_info.male.length > 0
      ? "male"
      : "female",
  rating: 0,
  review: "",
  publish_date: "",
});
const photos = ref([] as File[]);
const currentCartPvz = ref(review.value.gender_info[reviewForm.value.gender]);

function updateCurrentCartPvz() {
  currentCartPvz.value = review.value.gender_info[reviewForm.value.gender];
  if (currentCartPvz.value.length > 0) {
    reviewForm.value.cart = currentCartPvz.value[0].id;
  } else {
    reviewForm.value.cart = null;
  }
}

watch(reviewForm.value, () => {
  currentCartPvz.value =
    review.value.gender_info[reviewForm.value.gender] || [];
});

function handleFileUpload(event: Event) {
  const input = event.target as HTMLInputElement;
  if (input.files) {
    photos.value = Array.from(input.files);
  }
}
const errors = ref({ rating: "", publishDate: "" });
function validateReviewForm() {
  let success = true;
  if (reviewForm.value.publish_date == "") {
    errors.value.publishDate = "Выберите дату";
    success = false;
  }
  if (reviewForm.value.rating < 1) {
    errors.value.rating = "Поставьте рейтинг";
    success = false;
  }
  return success;
}
// Метод для обработки отправки формы
function submitReview() {
  // Логика обработки формы
  const success = validateReviewForm();
  if (success) {
    const formData = new FormData();
    formData.append("article", reviewForm.value.article);
    if (reviewForm.value.cart) {
      formData.append("cart", reviewForm.value.cart.toString());
    }
    formData.append("rating", reviewForm.value.rating.toString());
    formData.append("publish_date", reviewForm.value.publish_date);
    formData.append("review", reviewForm.value.review);
    if (photos.value.length === 0) {
      formData.append("photos", new Blob(), "marker_for_empty_list");
    } else {
      photos.value.forEach((file) => {
        formData.append(`photos`, file);
      });
    }
    showModal.value = false;
    emit("update", formData);
  } else {
    return;
  }
}

// Получение текущей даты и даты через две недели
const currentDate = new Date();
const twoWeeksFromNow = new Date(
  currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
);

const minDate = ref(getFormattedDate(currentDate));
const maxDate = ref(getFormattedDate(twoWeeksFromNow));
</script>
