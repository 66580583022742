<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(item, index) in crumbs" :key="index">
        <a :href="item.url" v-if="item.url" @click="$router.push(item.url)">{{
          item.text
        }}</a>
        <template v-else>
          {{ item.text }}
        </template>
      </li>
    </ol>
  </nav>
</template>
<script setup lang="ts">
import { defineProps, ref } from "vue";

const props = defineProps<{
  crumbs: {
    text: string;
    url?: string | undefined;
  }[];
}>();
const crumbs = ref(props.crumbs);
</script>
