<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-sm p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Массовое добавление</h5>
          <CloseButton @click="emit('closeicon')"></CloseButton>
        </div>
        <div class="modal-body">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" href="#">Буфер обмена</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">Импорт из Excel</a>
            </li> -->
          </ul>
          <div class="mt-3">
            <label for="product-list" class="form-label">Список товаров</label>
            <textarea
              id="product-list"
              class="form-control"
              placeholder="Артикулы через пробел, запятую или перенос строки"
              rows="5"
              v-model="articles"
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="emit('closeicon')"
          >
            Отмена
          </button>
          <MyButton class="mt-2 mx-1" @click="AddArticles">Добавить</MyButton>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { defineEmits, ref } from "vue";
import { VueFinalModal } from "vue-final-modal";
import MyButton from "@/components/UI/MyButton.vue";
import CloseButton from "@/components/UI/CloseButton.vue";

const emit = defineEmits<{
  (e: "closeicon"): void;
  (e: "update", array: Array<string>): void;
}>();
const articles = ref("");
const AddArticles = () => {
  let arrart = articles.value.split(/[\s,]+/);
  emit("update", arrart);
};
</script>
