<template>
  <div class="custom-number-input d-flex align-items-center">
    <button @click="decrement" class="btn btn-outline-secondary btn-sm">
      -
    </button>
    <span class="count mx-2"
      >{{ initialValue }}
      <span v-if="addedValue > 0">+ {{ addedValue }}</span></span
    >
    <button @click="increment" class="btn btn-outline-secondary btn-sm">
      +
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  initialValue: {
    type: Number,
    required: true,
  },
  modelValue: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update"]);

const addedValue = ref(props.modelValue ? props.modelValue : 0);

const increment = () => {
  if (addedValue.value < 100) {
    addedValue.value++;
    emit("update", addedValue.value);
  }
};

const decrement = () => {
  if (addedValue.value > 0) {
    addedValue.value--;
    emit("update", addedValue.value);
  }
};
</script>

<style scoped>
.custom-number-input {
  display: flex;
  align-items: center;
  background-color: rgb(237, 235, 235);
}

.custom-number-input .count {
  margin: 0 10px;
  min-width: 60px;
  text-align: center;
}

.custom-number-input .btn {
  min-width: 30px;
}
</style>
