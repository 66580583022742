import axios from "@/axiosInstance";
import { ref } from "vue";
import { IReview, IProductReview } from "../intefaces";
import store from "@/store";

class ReviewService {
  AvaibleReviews = ref(Array<IProductReview>());
  isLoading = ref(true);
  Reviews = ref(Array<IReview>());

  async fetchingAVReviews(avaible: boolean) {
    try {
      const response = await axios.get(`repayment/get/reviews`, {
        params: { av: avaible },
      });
      if (avaible) {
        this.AvaibleReviews.value = response.data.products;
      } else {
        this.Reviews.value = response.data;
      }
    } catch (e) {
      alert("Error");
    } finally {
      this.isLoading.value = false;
    }
  }

  getAvailableReview(avaible: boolean) {
    // await this.fetchingAVReviews(avaible);
    this.fetchingAVReviews(avaible);
    return { isLoading: this.isLoading, AvaibleReviews: this.AvaibleReviews };
  }

  getPublishedReviews(avaible: boolean) {
    // const isReviewLoading = ref(true);
    this.fetchingAVReviews(avaible);
    return { isReviewLoading: this.isLoading, Reviews: this.Reviews };
  }

  async senReview(newReview: FormData) {
    const response = await axios.post(
      "repayment/post/review/create",
      newReview,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (response.status == 200) {
      store.dispatch("refreshBalance");
      return true;
    } else return false;
  }
}

export default new ReviewService();
