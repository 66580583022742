<template>
  <div
    class="card bg-light mb-3 m-3"
    style="max-width: 18rem; margin-bottom: 1.5rem !important"
  >
    <div class="card-header skeleton"></div>
    <div class="card-body">
      <div class="card-title skeleton"></div>
      <div class="card-text skeleton"></div>
      <div class="card-text skeleton"></div>
      <div class="skeleton-btn"></div>
    </div>
  </div>
</template>

<style scoped>
.skeleton,
.skeleton-btn {
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeleton {
  height: 20px;
  margin: 12px 0;
  animation: skeleton-loading 1.5s infinite ease-in-out;
}

.card-header.skeleton {
  height: 20px;
  width: 60%;
  margin-bottom: 12px; /* Добавлен отступ под заголовком */
}

.card-title.skeleton {
  width: 70%;
  height: 1.25rem; /* Высота заголовка */
  margin-bottom: 10px; /* Отступ под заголовком */
}

.card-text.skeleton {
  width: 80%;
  height: 1rem; /* Высота текста */
  margin-bottom: 10px; /* Отступ между строками текста */
}

.skeleton-btn {
  width: 50%;
  height: 38px; /* Высота кнопки */
  margin-top: 20px; /* Отступ сверху */
}

@keyframes skeleton-loading {
  0% {
    background-color: #cccccc;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #cccccc;
  }
}
</style>
