import axios from "@/axiosInstance";
import { ref } from "vue";
import { ICartWarmProduct } from "../interfaces"; // Убедитесь, что путь до интерфейсов правильный
import store from "@/store";

class CartWarmService {
  carts = ref<Array<ICartWarmProduct>>([]);
  isLoading = ref(true);

  async fetching() {
    this.isLoading.value = true;
    try {
      const response = await axios.get(`warming/get/cartwarms`);
      this.carts.value = response.data;
    } catch (e) {
      alert("Error");
    } finally {
      this.isLoading.value = false;
    }
  }

  async createCarts(newCart: any) {
    try {
      const response = await axios.post(
        "warming/post/cartwarm/create",
        newCart.value
      );
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getCarts() {
    // onMounted(this.fetching);
    this.fetching(); // При первом вызове getLikes он автоматически загружает данные
    return { isLoading: this.isLoading, carts: this.carts };
  }
}

export default new CartWarmService();
