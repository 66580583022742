import axios from "axios";
import store from "./store";
// import router from "./router";
import { ref, Ref } from "vue";
import { notify } from "@kyvg/vue3-notification";

interface Subscriber {
  (accessToken: string): void;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
});

// Добавление интерсептора запроса для вставки токена доступа
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.state.access_token;
    // console.log(token);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const isRefreshing: Ref<boolean> = ref(false);
const subscribers: Subscriber[] = [];

function onTokenRefreshed(accessToken: string) {
  subscribers.forEach((callback) => callback(accessToken));
}

function addSubscriber(callback: Subscriber) {
  subscribers.push(callback);
}

async function refreshToken(): Promise<string> {
  if (!isRefreshing.value) {
    isRefreshing.value = true;
    try {
      const response = await axiosInstance.post("/token/refresh-token", {
        refreshToken: store.state.user.refresh_token,
      });
      const accessToken: string = response.data.access_token;
      isRefreshing.value = false;
      onTokenRefreshed(accessToken);
      store.commit("setAccessToken", accessToken);
      return accessToken;
    } catch (error) {
      isRefreshing.value = false;
      throw error;
    }
  } else {
    // store.dispatch("LogOut");
    // router
    //   .push("/auth")
    //   .catch((err) => console.error("Router redirect error:", err));
    // Ожидаем обновления токена другим запросом
    return new Promise<string>((resolve) => {
      addSubscriber((accessToken: string) => {
        resolve(accessToken);
      });
    });
  }
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401) {
      if (!originalRequest._retry) {
        if (originalRequest.url === "token") {
          return Promise.reject(error);
        }
        if (originalRequest.url == "/token/refresh-token") {
          // console.log("orig if");
          store.dispatch("LogOut");
        }
        originalRequest._retry = true;
        try {
          const accessToken = await refreshToken();
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          store.dispatch("LogOut");
          return Promise.reject(refreshError);
        }
      } else {
        store.dispatch("LogOut");
      }
    } else if (error.response?.status == 409) {
      notify({
        title: "Пополните баланс.",
        text: "Не хватает средств на балансе аккаунта.",
      });
    } else {
      notify({
        text: "Что-то пошло не так...",
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
