import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthView from "../views/auth/AuthView.vue";
import ProfileView from "../views/auth/ProfileView.vue";
import ConfirmAccount from "@/views/auth/ConfirmAccount.vue";
import RepaymentView from "../views/repayment/RepaymentView.vue";
import AddRepayment from "../views/repayment/AddRepayment.vue";
import RepaymentDetail from "../views/repayment/RapaymentDetail.vue";
import LikeProduct from "../views/warming/LikeProduct.vue";
import QuestionsProduct from "../views/warming/QuestionsProduct.vue";
import WarmingCart from "../views/warming/WarmingCart.vue";
import ReviewsLike from "@/views/warming/ReviewsLike.vue";
import ReviewsPage from "../views/repayment/ReviewPage.vue";
import DeliveryPage from "../views/repayment/DeliveryPage.vue";
import TarifPage from "../views/payment/TarifPage.vue";
import PaymentHistoryPage from "@/views/payment/PaymentHistoryPage.vue";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView.vue";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    name: "auth",
    component: AuthView,
    meta: { title: "Вход в личный кабинет" },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: { title: "Профиль", requiresAuth: true },
  },
  {
    path: "/verify",
    name: "verify",
    component: ConfirmAccount,
    meta: { title: "Подтверждение аккаунта" },
  },
  {
    path: "/recovery",
    name: "recovery",
    component: ForgotPasswordView,
    meta: { title: "Востановление пароля" },
  },
  {
    path: "/repayment",
    name: "repayment",
    component: RepaymentView,
    meta: { title: "Выкупы", requiresAuth: true },
  },
  {
    path: "/repayment/create",
    name: "repayment-create",
    component: AddRepayment,
    meta: { title: "Добавить выкуп", requiresAuth: true },
  },
  {
    path: "/repayment/:id",
    name: "repayment-detail",
    component: RepaymentDetail,
    meta: { title: "Детали выкупа :id", requiresAuth: true },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: ReviewsPage,
    meta: { title: "Отзывы", requiresAuth: true },
  },
  {
    path: "/delivery",
    name: "delivery",
    component: DeliveryPage,
    meta: { title: "Доставки", requiresAuth: true },
  },
  {
    path: "/likes",
    name: "likes",
    component: LikeProduct,
    meta: { title: "Лайки на товары и бренды", requiresAuth: true },
  },
  {
    path: "/questions",
    name: "qustions",
    component: QuestionsProduct,
    meta: { title: "Вопросы к товарам", requiresAuth: true },
  },
  {
    path: "/carts",
    name: "carts",
    component: WarmingCart,
    meta: { title: "Добавить в корзину", requiresAuth: true },
  },
  {
    path: "/reviewlike",
    name: "reviewlike",
    component: ReviewsLike,
    meta: { title: "Лайки на отзывы", requiresAuth: true },
  },
  {
    path: "/tarifs",
    name: "tarifs",
    component: TarifPage,
    meta: { title: "Наши тарифы", requiresAuth: true },
  },
  {
    path: "/history",
    name: "history",
    component: PaymentHistoryPage,
    meta: { title: "Финансовая история аккаунта", requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Устанавливаем заголовок страницы из мета-данных
  if (typeof to.meta.title === "string") {
    document.title = to.meta.title;
  } else {
    document.title = "MyMp";
  }

  // Проверяем, требует ли маршрут аутентификации
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.loginState) {
      // Если пользователь не аутентифицирован, перенаправляем на страницу авторизации
      next({ name: "auth" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
