<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex-col m-auto mx-auto p-1 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-dialog m-3" role="document">
      <form method="post" id="forgotpassForm">
        <div class="modal-content">
          <div class="modal-header mb-3">
            <h5 class="modal-title">Забыли пароль?</h5>
            <CloseButton @click="emit('closeicon')"></CloseButton>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label
                >Электронная почта <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name="forgotemail"
                id="forgotemail"
                class="form-control"
                placeholder="Введите ваш действительный адрес электронной почты..."
                v-model="email"
                required
              />
            </div>
          </div>
          <div class="modal-footer d-flex align-items-center">
            <!-- <button
              type="button"
              class="btn btn-secondary me-3"
              data-bs-dismiss="modal"
            >
              Войти
            </button> -->
            <MyButton @click="emitEmail">Отправить запрос</MyButton>
          </div>
        </div>
      </form>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import CloseButton from "@/components/UI/CloseButton.vue";
import MyButton from "@/components/UI/MyButton.vue";
import { VueFinalModal } from "vue-final-modal";
import { defineEmits, ref } from "vue";

const emit = defineEmits<{
  (e: "closeicon"): void;
  (e: "update", email: string): void;
}>();

const email = ref("");
const emitEmail = () => {
  emit("update", email.value);
};
</script>
<style scoped>
.custom-modal-size {
  margin: auto;
  max-width: 300px; /* Уменьшает максимальную ширину */
  padding: 10px; /* Управляет отступами */
}
</style>
