<template>
  <div class="accordion mb-4 pr-0 w-100" id="purchaseList">
    <div class="accordion-item" v-for="(cart, index) in carts" :key="cart.id">
      <h2 class="accordion-header" :id="`heading${cart.id}`">
        <button
          class="accordion-button mr-3"
          :class="{
            collapsed: !cart.show,
          }"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse${cart.id}`"
          :aria-expanded="cart.show"
          :aria-controls="`collapse${cart.id}`"
          @click="showCart(index)"
        >
          Выкуп №{{ cart.id }}
          <template v-if="!cart.show">
            <swiper
              :modules="[Virtual]"
              :slides-per-view="3"
              :space-between="2"
              virtual
              style="width: 14rem"
            >
              <!-- Slides -->
              <swiper-slide
                class="swiper-slide"
                v-for="(item, index) in cart.items"
                :key="index"
                :virtualIndex="index"
              >
                <!-- {{ index }} -->
                <ImageContainer
                  :photo_url="item.product.photo_url"
                ></ImageContainer>
              </swiper-slide>
            </swiper>
          </template>
          <div class="button-group d-flex justify-content-end align-items-end">
            <template v-if="cart.loading">
              <div class="d-flex justify-content-end">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Загрузка...</span>
                </div>
              </div>
            </template>
            <template v-else>
              <MyButton
                v-if="cart.status == 'CREATED'"
                @click.stop="Payment(cart)"
                >Оплатить</MyButton
              >
              <MyButton
                v-if="
                  (cart.status == 'ORDER' && cart.qrcode) ||
                  // (cart.is_payment && !cart.is_take) ||
                  (cart.items.some(
                    (element) => element.status === 'DELIVERY'
                  ) &&
                    router.name == 'delivery')
                "
                @click.stop="openQr(cart)"
                >QrCode
                <MyTimer
                  v-if="cart.qrcode?.ttl && cart.status == 'ORDER'"
                  :initialTime="cart.qrcode.ttl"
                  @timer-finished="handleTimerFinished(cart)"
                />
              </MyButton>
            </template>
          </div>
        </button>
      </h2>
      <div
        :id="`collapse${cart.id}`"
        class="accordion-collapse collapse"
        :class="{
          show: cart.show,
        }"
        :aria-labelledby="`heading${cart.id}`"
        data-bs-parent="#purchaseList"
      >
        <div class="accordion-body">
          <purchase-item
            :cart="cart"
            :manage="manage"
            @open-status="emiStatus"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import PurchaseItem from "./PurchaseItem.vue";
import { IRepaymentCart } from "../intefaces";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyTimer from "@/components/MyTimer.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Virtual } from "swiper/modules";
import { useRoute } from "vue-router";

const router = useRoute();
const props = defineProps<{
  carts: Array<IRepaymentCart>;
  manage: boolean;
}>();
const emit = defineEmits(["open-status", "payment", "open-qr", "timer-finish"]);
// Замените на реальные данные
const carts = ref(props.carts);
function showCart(index: number) {
  if (carts.value) {
    carts.value[index].show = !carts.value[index].show;
  }
}
function Payment(cart: IRepaymentCart) {
  emit("payment", cart);
}
function openQr(cart: IRepaymentCart) {
  emit("open-qr", cart);
}
const emiStatus = (id: number) => {
  emit("open-status", id);
};
const handleTimerFinished = (cart: IRepaymentCart) => {
  emit("timer-finish", cart);
};
</script>
<style scoped>
.button-group {
  margin-left: auto;
  margin-right: 10px;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.accordion-button .d-flex {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .accordion-button .d-flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .button-group {
    width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
  }
}
</style>
