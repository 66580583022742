<template>
  <select
    class="form-select form-select-md"
    aria-label=".form-select-sm example"
    :value="modelValue"
    @change="updateSelect"
    :disabled="options.length == 0"
  >
    <option v-for="option in options" :key="option.name" :value="option.value">
      {{ option.name }}
    </option>
  </select>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, ref } from "vue";

const props = defineProps<{
  modelValue: string | number;
  options: {
    name: string | number;
    value: string | number;
  }[];
}>();

const emit = defineEmits(["update:modelValue"]);
function updateSelect(event: Event) {
  emit("update:modelValue", (event.target as HTMLInputElement).value);
}

// Установка первого значения массива options в качестве значения по умолчанию
const options = ref(props.options);
if (options.value.length > 0) {
  emit("update:modelValue", options.value[0].value);
}
</script>
<style scoped>
select {
  font-size: smaller;
}
</style>
