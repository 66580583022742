import axios from "@/axiosInstance";
import { IRepaymentProduct } from "@/views/repayment/intefaces";
import { ref } from "vue";
import { uuid } from "vue-uuid";
import { FeedbackData } from "./interfaces";

export const getId = () => {
  return uuid.v1();
};

class WBService {
  getWBURL(article: string) {
    return `https://www.wildberries.ru/catalog/${article}/detail.aspx`;
  }

  async getProduct(article: string) {
    const product = ref<IRepaymentProduct>();
    const response = await axios.get("/wbapi/get/product", {
      params: {
        article: article,
      },
    });
    product.value = response.data;
    if (product.value) {
      product.value.id = getId();
      product.value.count = 1;
      product.value.punkt_id = 0;
      if (product.value.sizes.length > 0) {
        product.value.cur_price = product.value.sizes[0].price / 100.0;
        product.value.max_count = product.value.sizes[0].qty;
        // const sizes = response.data.sizes;
        if (product.value.sizes[0].name === " 0") {
          product.value.sizes = [];
          product.value.size = "";
        } else {
          product.value.size = product.value.sizes[0].name;
        }
      }
    }
    return product.value;
  }

  async SearchProductTask(article: string, query: string) {
    const search = {
      article: article,
      query: query,
      lat: "37.617644",
      lon: "55.755819",
    };

    if (query.length > 4) {
      try {
        const response = await axios.post(
          "/wbapi/get/search/product/task",
          search
        );
        return response.data.token;
      } catch {
        return null;
      }
    }
  }

  async getTaskResult(taskToken: string) {
    try {
      const response = await axios.get(
        `/wbapi/get_result/?task_token=${taskToken}`
      );
      return response.data.result;
    } catch {
      return null;
    }
  }

  async getReviewProduct(article: string) {
    const feedbacks = ref<FeedbackData>();
    const isLoading = ref(true);
    try {
      const response = await axios.get("/wbapi/get/feedbacks", {
        params: {
          article: article,
        },
      });
      feedbacks.value = response.data;
      isLoading.value = false;
    } catch {
      return null;
    } finally {
      isLoading.value = false;
    }
    return { isLoading: isLoading, feedbacks: feedbacks };
  }
}

export default new WBService();
