import axios from "@/axiosInstance";
import { ref } from "vue";
import { IQuestionProduct } from "../interfaces";
import store from "@/store";

class QuestionService {
  questions = ref<Array<IQuestionProduct>>([]);
  isLoading = ref(true);

  async fetching() {
    this.isLoading.value = true;
    try {
      const response = await axios.get(`warming/get/questions`);
      this.questions.value = response.data;
    } catch (e) {
      alert("Error");
    } finally {
      this.isLoading.value = false;
    }
  }

  async createQuestion(newQuestion: any) {
    try {
      const response = await axios.post(
        "warming/post/questions/create",
        newQuestion.value
      );
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getQuestions() {
    // onMounted(this.fetching);
    this.fetching(); // При первом вызове getLikes он автоматически загружает данные
    return { isLoading: this.isLoading, questions: this.questions };
  }
}

export default new QuestionService();
