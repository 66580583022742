<template>
  <button class="icon-button" @click="handleClick">
    <i :class="iconClass"></i>
  </button>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

defineProps<{
  iconClass: string;
}>();

const emits = defineEmits(["click"]);

const handleClick = () => {
  emits("click");
};
</script>

<style scoped>
.icon-button {
  width: 30px; /* Устанавливаем фиксированный размер кнопки */
  height: 30px; /* Устанавливаем фиксированный размер кнопки */
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.35rem;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.icon-button:hover {
  background-color: #e9ecef;
}

.icon-button i {
  font-size: 1rem;
  color: #6c757d;
}

.icon-button:hover i {
  color: #0d6efd; /* Цвет при наведении */
}
</style>
