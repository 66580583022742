<template>
  <div class="star-rating">
    <i
      v-for="index in 5"
      :key="index"
      class="star"
      :class="{ filled: index <= rating || index <= hoverIndex }"
      @click="setRating(index)"
      @mouseover="hoverIndex = index"
      @mouseleave="hoverIndex = 0"
      >&#9733;</i
    >
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, watch, onMounted, defineEmits } from "vue";

const props = defineProps<{
  modelValue: number;
}>();

const emit = defineEmits(["update:modelValue"]);

const rating = ref(props.modelValue);
const hoverIndex = ref(0);

watch(
  () => props.modelValue,
  (newVal) => {
    rating.value = newVal;
  }
);

const setRating = (index: number) => {
  rating.value = index;
  emit("update:modelValue", index);
};

onMounted(() => {
  rating.value = props.modelValue;
});
</script>

<style scoped>
.star-rating {
  font-size: 20px;
  color: #d3d3d3;
  cursor: pointer;
}

.star {
  color: #d3d3d3;
  transition: color 0.2s;
}

.star.filled {
  color: gold;
}
</style>
