<template>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Закрыть"
    @click="emit('closeicon')"
  ></button>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";
const emit = defineEmits<{
  (e: "closeicon"): void;
}>();
</script>

<style scoped>
/* Вы можете добавить дополнительные стили здесь, если нужно настроить внешний вид кнопки */
</style>
