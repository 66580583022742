<template>
  <div class="confirm-account">
    <h1>Подтверждение аккаунта</h1>
    <p v-if="isLoading">Подождите, идет обработка...</p>
    <p v-if="Error" class="error">{{ Error }}</p>
    <p v-else>Аккаунт успещно подтвержден</p>
    <p v-if="Message" class="success">{{ Message }}</p>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import AuthService from "./hooks/AuthService";

const route = useRoute();
const isLoading = ref(false);
const Error = ref("");
const Message = ref("");

const verifyAccount = async () => {
  const token = route.query.verify_token;
  console.log(token);
  if (!token) {
    Error.value = "Токен не предоставлен.";
    return;
  } else {
    if (typeof token === "string") {
      const { loading, error, message } = await AuthService.confirmAccount(
        token
      );
      isLoading.value = loading.value;
      Error.value = error.value;
      Message.value = message.value;
    }
  }
};

onMounted(verifyAccount);
</script>

<style scoped>
.confirm-account {
  text-align: center;
  padding: 20px;
}
.error {
  color: red;
}
.success {
  color: green;
}
</style>
