<template>
  <button type="button" class="btn btn-md btn-primary mb-2">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "my-button",
};
</script>
