<template>
  <div class="row">
    <form autocomplete="off">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label">Новый пароль</label>
          <input
            type="password"
            class="form-control"
            id="password"
            placeholder=""
            aria-label="password"
            v-model="passwordForm.password"
            autocomplete="new-password"
            @input="validatePassword"
          />
          <div
            v-if="passwordForm.password.length > 0 && !valid"
            style="color: red"
          >
            Пароль должен содержать как минимум одну заглавную букву, одну цифру
            и быть не менее 8 символов
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="mb-3">
        <label for="password2" class="form-label">Повторите новый пароль</label>
        <input
          type="password"
          class="form-control"
          id="password2"
          placeholder=""
          aria-label="password2"
          autocomplete="new-password"
          v-model="passwordForm.password2"
        />
        <div
          v-if="passwordForm.password != passwordForm.password2"
          style="color: red"
        >
          Пароли должны совпадать
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <MyButton @click="changePassword">Сохранить</MyButton>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, defineEmits } from "vue";
import MyButton from "@/components/UI/MyButton.vue";
const emit = defineEmits(["submit"]);
const passwordForm = ref({
  password: "",
  password2: "",
  errors: {
    password: "",
    password2: "",
  },
});
let valid: boolean;
const validatePassword = () => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
  valid = passwordRegex.test(passwordForm.value.password);
};
const changePassword = () => {
  if (passwordForm.value.password != passwordForm.value.password2) {
    passwordForm.value.errors.password2 = "Пароли не совпадают";
  } else {
    emit("submit", passwordForm.value.password);
  }
};
</script>
