<template>
  <span>{{ minutes }}:{{ formattedSeconds }}</span>
</template>

<script setup lang="ts">
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  defineEmits,
  defineProps,
} from "vue";

interface TimerProps {
  initialTime: number;
}

const props = defineProps<TimerProps>();
const emit = defineEmits(["timer-finished"]);

const timeRemaining = ref(props.initialTime);
const intervalId = ref<number | null>(null);

const minutes = computed(() => Math.floor(timeRemaining.value / 60));
const formattedSeconds = computed(() => {
  const seconds = timeRemaining.value % 60;
  return seconds < 10 ? `0${seconds}` : seconds;
});

const startTimer = () => {
  intervalId.value = setInterval(() => {
    if (timeRemaining.value > 0) {
      timeRemaining.value--;
    } else {
      clearInterval(intervalId.value!);
      emit("timer-finished");
    }
  }, 1000);
};

onMounted(() => {
  startTimer();
});

onBeforeUnmount(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});
</script>

<style scoped>
span {
  font-weight: bold;
}
</style>
