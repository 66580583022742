<template>
  <div class="cart-container">
    <div
      class="row d-flex justify-content-between align-items-center flex-wrap item-row"
      v-for="(item, index) in cart.items"
      :key="item.id"
    >
      <div class="col-3 col-sm-1 mb-2">
        <ImageContainer :photo_url="item.product.photo_url"></ImageContainer>
      </div>
      <div class="fw-bold col-9 col-sm-2">
        Артикул: {{ item.product.article }}
      </div>
      <div class="col-12 col-sm-4">Название: {{ item.product.name }}</div>
      <div class="col-6 col-sm-2">Цена: {{ item.cur_price }}</div>
      <div class="col-4 col-sm-2" v-if="item.status">
        {{ statusClassStatus(item.status).text }}
      </div>
      <div
        class="text-end mt-2 col-6 col-sm-1"
        v-if="(cart.status == 'PAYMENT' || cart.status == 'DELIVERY') && manage"
      >
        <MyButton @click="openStatusModal(item.id)">Статус</MyButton>
      </div>
      <hr v-if="index < cart.items.length - 1" class="w-100" />
    </div>
    <hr />
    <div class="mt-2 buyer-info" v-if="cart.fake_user">
      <span>Покупатель: {{ cart.fake_user.name }}</span>
      <span class="ms-2">Телефон: {{ cart.fake_user.phone }}</span>
    </div>
    <div class="mt-2 address-info">Адрес: {{ cart.punkt.address }}</div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { IRepaymentCart } from "../intefaces";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import MyButton from "@/components/UI/MyButton.vue";
import { statusClassStatus } from "@/hooks/";

defineProps<{
  cart: IRepaymentCart;
  manage: boolean;
}>();
const emit = defineEmits(["open-status"]);
const openStatusModal = function (id: number) {
  emit("open-status", id);
};
</script>
