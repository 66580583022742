<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-sm p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-header">
      <h5 class="modal-title">Статус заказа</h5>
      <CloseButton @click="emit('closeicon')"></CloseButton>
    </div>
    <div class="modal-body">
      <ul class="list-group list-group-flush list-scrollable">
        <li
          class="list-group-item d-flex align-items-start"
          v-for="(event, index) in trackingEvents"
          :key="index"
        >
          <div class="me-3">
            <span class="badge bg-primary rounded-circle">&nbsp;</span>
          </div>
          <div>
            <div class="fw-bold">{{ event.description }}</div>
            <div class="text-muted">
              <small>{{ event.date }}</small>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, Ref } from "vue";
import { VueFinalModal } from "vue-final-modal";
import { TrackingStatus } from "../intefaces";
import CloseButton from "@/components/UI/CloseButton.vue";

const props = defineProps<{
  items: Ref<Array<TrackingStatus> | undefined>;
}>();
const trackingEvents = ref(props.items);
const emit = defineEmits<{
  (e: "closeicon"): void;
}>();
</script>
<style scoped>
.modal-body {
  max-height: 300px; /* Задайте желаемую высоту */
  overflow-y: auto;
}

.list-scrollable {
  max-height: 100%;
  overflow-y: auto;
}
</style>
