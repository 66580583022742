<template>
  <div class="card text-bg-primary mb-3 me-3" style="max-width: 18rem">
    <div class="card-header">
      {{ tarif.alt_name }}
    </div>
    <div class="card-body text-white">
      <h5 class="card-title">1 ШТ.</h5>
      <p class="card-text">{{ tarif.cost }}₽ / {{ tarif.comment }}</p>
      <p class="card-footer-text mt-3"></p>
      <p class="card-footer-price fw-bold">{{ tarif.cost }}₽</p>
      <button type="button" class="btn btn-outline-light mt-3">Купить</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineComponent, defineProps } from "vue";
import { ITarif } from "../interfaces";

defineComponent({
  name: "CardComponent",
});
defineProps<{
  tarif: ITarif;
}>();
</script>
<style scoped>
:root {
  --bs-primary-rgb: #6c63ff;
}
.card {
  background-color: #6c63ff !important;
}
.card-header {
  background-color: #6c63ff;
  border-radius: 0.25rem 0.25rem 0 0;
}

.card-body {
  background-color: #6c63ff;
  color: #fff;
  padding: 1rem;
}

.card-footer-text {
  background: transparent;
  border-top: none;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.card-footer-price {
  background: transparent;
  border-top: none;
  color: #fff;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0;
}

.btn-light {
  color: #6c63ff;
  font-weight: bold;
}
</style>
