<template>
  <VueFinalModal
    class="modal-dialog-centered"
    content-class="flex flex-col mx-auto my-1 max-w-sm p-3 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-4 rounded"
  >
    <div class="modal-content">
      <div class="modal-header flex justify-end">
        <CloseButton @click="emit('closeicon')"></CloseButton>
      </div>
      <div class="modal-body w-120">
        <div class="text-center" v-if="qrcode">
          <qrcode-vue
            :value="qrcode"
            :render-as="'svg'"
            level="L"
            :width="300"
            :height="300"
          />
        </div>
        <div v-if="rec_code">Код получения: {{ rec_code }}</div>
      </div>
    </div>
  </VueFinalModal>
</template>
<script setup lang="ts">
import { Ref, defineEmits, defineProps, ref } from "vue";
import CloseButton from "@/components/UI/CloseButton.vue";
import { VueFinalModal } from "vue-final-modal";
import QrcodeVue from "qrcode.vue";
// import { Level, RenderAs } from "qrcode.vue";

const props = defineProps<{
  qrcode: Ref<string>;
}>();

const qrcode = ref(props.qrcode);
const rec_code = qrcode.value.split("_")[1];
const emit = defineEmits<{
  (e: "closeicon"): void;
}>();
</script>
<style scoped>
.modal-header {
  display: flex;
  justify-content: flex-end;
}
</style>
