<template>
  <div
    class="container mt-2 mb-4 d-flex justify-content-center align-items-center min-vh-100"
  >
    <div class="col-sm-5">
      <div class="confirm-account">
        <h1>Восстановление пароля</h1>
        <p v-if="isLoading">Подождите, идет обработка...</p>
        <div v-else>
          <ChangePasswordForm @submit="changePassword" />
        </div>
        <p v-if="Error" class="error">{{ Error }}</p>
        <p v-if="Message" class="success">{{ Message }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ChangePasswordForm from "./components/ChangePassswordForm.vue";
import AuthService from "./hooks/AuthService";
import { notify } from "@kyvg/vue3-notification";

const route = useRoute();
const router = useRouter();
const isLoading = ref(true);
const Error = ref("");
const Message = ref("");
const token = ref();
const getToken = async () => {
  if (route.query.verify_token) {
    token.value = route.query.verify_token;
    isLoading.value = false;
  } else {
    notify({
      text: "Не удалось получить токен",
    });
  }
};
const changePassword = async (newPassword: string) => {
  let res = await AuthService.recoveryPassword(newPassword, token.value);
  if (res == true) {
    notify({
      text: "Пароль успешно изменен.",
    });
    router.push("/auth");
  }
};
onMounted(getToken);
</script>
