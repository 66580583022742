<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
              Лайки на бренд или товар
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
          <div class="col-md-12">
            <form class="row g-3 justify-content-evenly">
              <div class="col-sm-6 col-md-3 mb-3">
                <label for="url" class="visually-hidden"
                  >Ссылка на товар или бренд</label
                >
                <TextInput
                  v-model="likeForm.url"
                  :placeholder="'Ссылка на товар или бренд'"
                  :id="'url'"
                ></TextInput>
              </div>
              <div class="col-sm-6 col-md-2 mb-3">
                <NumberInput
                  v-model="likeForm.count"
                  :step="1"
                  :max="100"
                ></NumberInput>
              </div>
              <div class="col-sm-6 col-md-2 mb-3">
                <MySelect :options="hours" v-model="likeForm.hours"></MySelect>
              </div>
              <div class="col-sm-6 col-md-2 mb-3">
                Стоимость:<br />
                {{ service?.cost }} Р/шт
              </div>
              <div class="col-sm-6 col-md-2 mb-3">
                <!-- Уменьшил размер колонки и добавил класс mb-3 для добавления отступов между элементами -->
                <MyButton @click="SendLikes">Добавить</MyButton>
              </div>
            </form>
          </div>

          <div class="col-md-12">
            <table class="table table-striped">
              <!--Table head-->
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ссылка на товар</th>
                  <th>Тип</th>
                  <th>Прогресс</th>
                  <th>Стоимость</th>
                  <th>Статус</th>
                  <th>Дата создания</th>
                  <th>Дата окончания</th>
                  <th></th>
                </tr>
              </thead>
              <!--Table head-->
              <!--Table body-->
              <tbody v-if="!LikeService.isLoading.value">
                <tr
                  v-for="(order, index) in LikeService.likes.value"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>
                    <div class="col-6">
                      <ImageContainer
                        :photo_url="order.product.photo_url"
                      ></ImageContainer>
                    </div>
                    <!-- <img
                    :src="order.image_url"
                    :alt="order.image_url"
                    width="50"
                    height="50"
                  /> -->
                  </td>
                  <td>{{ order.type }}</td>
                  <td>{{ order.done }}/{{ order.total }}</td>
                  <td v-if="service">{{ service.cost * order.total }} руб.</td>
                  <td>
                    <template v-if="order.done === order.total">
                      <StatusSpan :color-class="statusClassStatus('DONE').class"
                        >{{ statusClassStatus("DONE").text }}
                      </StatusSpan>
                    </template>
                    <template v-else>
                      <StatusSpan
                        :color-class="statusClassStatus('PROCCESS').class"
                        >{{ statusClassStatus("PROCCESS").text }}</StatusSpan
                      >
                    </template>
                  </td>
                  <td>{{ order.date_start }}</td>
                  <td>{{ order.date_end }}</td>
                </tr>
              </tbody>
              <template v-else>
                <RowSkeleton
                  v-for="index in 5"
                  :key="index"
                  :columns="9"
                ></RowSkeleton>
              </template>
              <!--Table body-->
            </table>
          </div>
        </div>
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import LayuotPage from "../LayuotPage.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySelect from "@/components/UI/MySelect.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import NumberInput from "@/components/UI/NumberInput.vue";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import LikeService from "./hooks/LikeService";
import { getService } from "@/hooks";
import { IService } from "@/hooks/interfaces";
import { statusClassStatus } from "@/hooks";
import StatusSpan from "@/components/UI/StatusSpan.vue";
// import { useRoute } from "vue-router";
// import axios from "axios";
// const route = useRoute();
const likeForm = ref({
  url: "",
  hours: "3",
  count: 10,
});
const crumbs = [
  {
    text: "Лайки на бренд или товар",
    url: "/like",
  },
  {
    text: "Лайки",
  },
];
const hours = [
  { value: "3", name: "3 часа" },
  { value: "12", name: "12 часов" },
  { value: "24", name: "1 день" },
  { value: "72", name: "3 дня" },
  { value: "168", name: "7 дней" },
  { value: "336", name: "14 дней" },
];
const service = ref<IService>();
onMounted(async () => {
  await LikeService.getLikes();
  service.value = (await getService("question")).value;
});
const SendLikes = async () => {
  const res = await LikeService.createLikes(likeForm);
  console.log(res);
  if (res) {
    await LikeService.fetching();
  }
};
</script>
