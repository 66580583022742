<template>
  <LayuotPage>
    <div class="container-sm ps-7 p-0">
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
            Доставки
          </h2>
          <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
        </div>
      </div>
      <div class="row" v-if="!isLoading">
        <PurchaseList
          :carts="carts"
          :manage="manage"
          @open-status="ShowModal"
          @open-qr="getQR"
        ></PurchaseList>
      </div>
      <div class="row" v-else>
        <AccordionSkeleton></AccordionSkeleton>
      </div>
    </div>
    <ModalsContainer />
  </LayuotPage>
</template>
<script setup lang="ts">
import { ref } from "vue";
import PurchaseList from "./components/PurchaseList.vue";
import LayuotPage from "../LayuotPage.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import RepaymentService from "./hooks/RepaymentService";
import StatusModal from "./modals/StatusModal.vue";
import AccordionSkeleton from "@/components/skeletons/AccordionSkeleton.vue";
import { ModalsContainer, useModal } from "vue-final-modal";
import { IRepaymentCart, TrackingStatus } from "./intefaces";
import QRModal from "./modals/QRModal.vue";

const crumbs = [
  {
    text: "Доставки",
    url: "/reviews",
  },
  {
    text: "Доставки",
  },
];
const manage = true;
const { isLoading, carts } = RepaymentService.getDelivery();

const currentItemStatus = ref<Array<TrackingStatus> | undefined>();
const ShowModal = async (id: number) => {
  const items = (await RepaymentService.getItemStatus(id)).items;
  if (items) {
    currentItemStatus.value = items.value; // Обновляем значение currentItemStatus
    console.log(currentItemStatus.value);
    open(); // Открываем QR-код
  }
};
const cartQr = ref(
  // "https://qr.nspk.ru/AD1P00098AILAT6Q80CPLJBQDKU1AI2O?type=02&bank=100000000111&sum=141600&cur=RUB&crc=846A"
  ""
);
const getQR = async (cart: IRepaymentCart) => {
  const newCartQr = await RepaymentService.getQr(cart.id);
  if (newCartQr.value) {
    cartQr.value = newCartQr.value.qr_url; // Обновляем значение cartQr реактивной ссылки
    // cartQr.value = "sdsdsdsd";
    cart.qrcode = newCartQr.value; // Обновляем QR-код в объекте cart
    console.log(cartQr.value);
    openQR(); // Открываем QR-код
  }
};
const { open, close } = useModal({
  component: StatusModal,
  attrs: {
    items: currentItemStatus,
    onCloseicon() {
      close();
    },
    // onUpdate(item_id: number) {
    //   currentItemStatus.value = RepaymentService.getItemStatus(item_id);
    //   open();
    // },
  },
});
const { open: openQR, close: closeQR } = useModal({
  component: QRModal,
  attrs: {
    qrcode: cartQr,
    onCloseicon() {
      closeQR();
    },
  },
});
</script>
