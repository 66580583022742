import { onMounted, ref } from "vue";
import axios from "@/axiosInstance";
import { IPaymentHistory, ITarif } from "../interfaces";

class PaymentService {
  getTarifs() {
    const tarifs = ref(Array<ITarif>());
    const isLoading = ref(true);
    const fetching = async () => {
      try {
        const response = await axios.get("payment/get/services");
        tarifs.value = response.data;
      } catch (e) {
        alert("Error");
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(fetching);
    return { isLoading, tarifs };
  }
  getHistory() {
    const transactions = ref(Array<IPaymentHistory>());
    const isLoading = ref(true);
    const fetching = async () => {
      try {
        const response = await axios.get("payment/get/history");
        transactions.value = response.data;
      } catch (e) {
        alert("Error");
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(fetching);
    return { isLoading, transactions };
  }
}

export default new PaymentService();
