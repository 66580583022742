import { createStore, Store } from "vuex";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import { InjectionKey } from "vue";
import axios from "@/axiosInstance";
import qs from "qs";
import router from "@/router";
import pointsModule, { PointsState } from "./modules/points";
import { refresh_token_expire, access_token_exprie } from "@/constants";

export interface IAuth {
  refresh_token: string;
  email: string;
  phone: string;
}

export interface IState {
  user: IAuth;
  loginState: boolean;
  access_token: string;
  balance: number;
  isCollapse: boolean;
  points: PointsState;
}

export const key: InjectionKey<Store<IState>> = Symbol();

function saveUserToCookies(user: IAuth) {
  const expiresTime = new Date(new Date().getTime() + refresh_token_expire);
  Cookies.set("user", JSON.stringify(user), {
    expires: expiresTime,
    secure: true,
  });
}

function removeUserFromCookies() {
  Cookies.remove("user");
}

const store = createStore<IState>({
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.get(key),
      setState: (key, state) => saveUserToCookies(state.user),
    }),
  ],
  state: {
    user: {
      refresh_token: "",
      email: "",
      phone: "",
    },
    access_token: "",
    balance: 0,
    loginState: false,
    isCollapse: false,
    points: {
      markers: [],
      isLoading: true,
    },
  },
  mutations: {
    setLocalUser(state, user: IAuth) {
      state.user = user;
      state.loginState = true;
      saveUserToCookies(user);
    },
    setAccessToken(state, token: string) {
      // console.log("in store", token);
      state.access_token = token;
      Cookies.set("access_token", token, {
        expires: new Date(new Date().getTime() + access_token_exprie),
        secure: true,
      });
    },
    setBalance(state, balance: number) {
      state.balance = balance;
    },
    loginOut(state) {
      state.loginState = false;
      state.user = {
        refresh_token: "",
        email: "",
        phone: "",
      };
      state.access_token = "";
      Cookies.remove("access_token");
      removeUserFromCookies();
      router.push("/auth");
    },
    syncLoginState(state) {
      const user = Cookies.get("user");
      const access_token = Cookies.get("access_token");
      if (access_token) {
        state.access_token = access_token;
      }
      if (user) {
        state.user = JSON.parse(user);
        if (state.user.refresh_token) {
          state.loginState = true;
        } else {
          router.push("/auth");
        }
      }
    },
    setCollapsed(state) {
      state.isCollapse = !state.isCollapse;
    },
  },
  getters: {
    isLoggedIn: (state) => state.loginState,
    getUser: (state) => state.user,
    getCollapse: (state) => state.isCollapse,
  },
  actions: {
    Restore: ({ commit }) => {
      commit("syncLoginState");
      store.dispatch("refreshBalance");
    },
    LogOut: ({ commit }) => {
      commit("loginOut");
    },
    async refreshBalance({ commit }) {
      try {
        const response = await axios.get("/payment/get/balance");
        if (response.status === 200) {
          commit("setBalance", response.data.balance);
        }
      } catch (error) {
        this.state.loginState = false;
        console.error("Login error:", error);
        throw error;
      }
    },
    async login({ commit }, credentials: { email: string; password: string }) {
      try {
        const response = await axios.post(
          "token",
          qs.stringify({
            username: credentials.email,
            password: credentials.password,
          }),
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        );
        if (response.status === 201 || response.status === 200) {
          const user = {
            refresh_token: response.data.refresh_token,
            email: credentials.email,
            phone: "",
          };
          commit("setAccessToken", response.data.access_token);
          commit("setLocalUser", user);
          this.dispatch("refreshBalance");
        }
      } catch (error) {
        this.state.loginState = false;
        console.error("Login error:", error);
        throw error;
      }
    },
    ToggleCollapse: ({ commit }) => {
      commit("setCollapsed");
    },
  },
  modules: {
    points: pointsModule,
  },
});

export default store;
