<template>
  <div class="accordion" id="skeletonAccordion">
    <div class="accordion-item" v-for="index in 3" :key="index">
      <h2 class="accordion-header" :id="`skeletonHeading${index}`">
        <button class="accordion-button collapsed" type="button" disabled>
          <div class="skeleton-block w-50"></div>
          <div class="skeleton-block w-25 ms-auto"></div>
        </button>
      </h2>
      <div
        :id="`skeletonCollapse${index}`"
        class="accordion-collapse collapse"
        aria-labelledby="`skeletonHeading${n}`"
      >
        <div class="accordion-body">
          <div class="skeleton-block w-100 mb-2"></div>
          <div class="skeleton-block w-80 mb-2"></div>
          <div class="skeleton-block w-90"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.skeleton-block {
  height: 20px;
  background-color: #e9ecef;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
